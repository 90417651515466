import { useMutation, useQuery } from '@apollo/client';
import { gray } from 'color-name';
import i18next from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Col } from 'react-bootstrap';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import {
  CREATE_HANDBOOK_STATS,
  UPDATE_ONETEAM_EMPLOYEEHANDBOOK,
} from '../../app/data/mutations/handbook';
import { GET_TEAM_EMPLOYEE_HANDBOOK } from '../../app/data/queries/landing-page';
import { AddIcon, previewIcon, FileMIcon } from '../../assets/icons';
import { formatDateTime, getMaximumDate, changeLanguage } from '../../common/utils';
import {
  AddNewBlock,
  ChapterEditor,
  BuilderEditOptions,
  GoBack,
  FilePreview,
  OtherFilesView,
  UpdateFilesDialog,
  WidgetInput,
} from '../../components';
import { FormWidget } from '../../components/FormWidget';
import { HandbookForm } from '../../components/HandbookForm';
import { TalentAdoreFormWidget } from '../../components/TalentAdoreFormWidget';
import { FileManager } from '../FileManager';
import { useFormBuilder } from './hooks/useFormBuilder';
import { useHandbookBuilder } from './hooks/useHandbookBuilder';
import { useOnScreen } from './hooks/useOnScreen';
import { Loader } from '../Loader';

import widgetTypeService from '../../service/WidgetTypeService';

import './style.scss';

export const HandbookBuilder = ({
  readOnly = false,
  isTemplate = false,
  fromLandingPage = false,
}) => {
  const { id, teamId } = useParams();
  const builderRef = useRef(null);
  const [readWidget, setReadWidget] = useState(-1);
  const [scrollIndex, setScrollIndex] = useState(0);
  const [isConfirm, setIsConfirm] = useState(false);
  const [widgetTypes, setWidgetTypes] = useState(null)

  const {
    data,
    updateWidget,
    showFileManager,
    setShowFileManager,
    handbookWidgets,
    setHandbookWidgets,
    loading,
    refetch,
  } = useHandbookBuilder(id, isTemplate);

  useEffect(() => {
    widgetTypeService.getTypes()
      .then(widgetTypes => {
        setWidgetTypes(widgetTypes)
      })
      .catch((error) =>  {
        setWidgetTypes([])
        console.error(error)
      })
  }, []);

  const { data: teamEmployeeHandbook } = useQuery(GET_TEAM_EMPLOYEE_HANDBOOK, {
    variables: { id: teamId },
    skip: !fromLandingPage,
  });
  
  const language = get(teamEmployeeHandbook, 'teamEmployeeHandbook.teamHandbook.team.lang')

  useEffect(() => {
    changeLanguage(language)
  }, [language])

  const useFormBuilder2 = useFormBuilder;

  const history = useHistory();
  const { t } = useTranslation()

 

  if (loading  || !widgetTypes) return <Loader />;

  return (
    <React.Fragment>
      <Col>
        <GoBack
          history={history}
          fromLandingPage={fromLandingPage}
          isTemplate={isTemplate}
          t={t}
        />
        {!readOnly && (
          <div className="d-flex justify-content-between align-items-end mt-3">
            <p
              onClick={() => setShowFileManager(!showFileManager)}
              className="nav-btn text-uppercase"
            >
              <img src={FileMIcon} className="mr-2" alt="File Manager" style={{ height: '16px' }} />
              {i18next.t('File manager')}
            </p>
            <div className="d-flex justify-content-start align-items-center">
              <Link
                to={{
                  pathname: (isTemplate ? '/template/view/' : '/chapter/view/') + id,
                  state: { shouldGoBack: true },
                }}
                className="btn btn-link text-capitalize mr-3 mb-0 d-flex justify-content-start align-items-center py-0 mb-3"
              >
                <img src={previewIcon} className="mr-2" alt="preview" style={{ height: '13px' }} />
                <div className="text-uppercase font-weight-bold">{i18next.t('Preview')}</div>
              </Link>
              <p className="nav-text ">{` ${i18next.t('Last saved on')} ${formatDateTime(
                getMaximumDate(
                  handbookWidgets,
                  data?.[isTemplate ? 'template' : 'handbook']?.updatedAt
                )
              )}`}</p>
            </div>
          </div>
        )}
        {readOnly && <div className='mobile' style={{ marginTop: '58px' }} />}
        {showFileManager ? (
          <FileManager
            asComponent
            onCloseBlock={() => setShowFileManager(!showFileManager)}
            isShowCloseButton={true}
          />
        ) : (
          <div ref={builderRef}>
            {handbookWidgets.length > 0 &&
              fromLandingPage &&
              handbookWidgets.map((widget, index) => {
                const widgetType = widget.widgetType.name.toLowerCase();
                const backgroundColor = widget.background?.color ?? '#F8D9D9';
                const backgroundImage = widget.background?.image?.url ?? '';
                const textColor = widget.textColor ?? '#000000';

                return (
                  <div>
                    <HandbookWidgets
                      builderRef={builderRef}
                      widget={widget}
                      index={index}
                      widgetType={widgetType}
                      backgroundColor={backgroundColor}
                      backgroundImage={backgroundImage}
                      textColor={textColor}
                      handbookWidgets={handbookWidgets}
                      setHandbookWidgets={setHandbookWidgets}
                      id={id}
                      refetch={refetch}
                      readOnly={readOnly}
                      isTemplate={isTemplate}
                      updateWidget={updateWidget}
                      useFormBuilder={useFormBuilder2}
                      key={widget.id}
                      fromLandingPage={fromLandingPage}
                    />
                    <EmptyComp
                      handbookWidgets={handbookWidgets}
                      index={index}
                      teamId={teamId}
                      readWidget={readWidget}
                      setReadWidget={setReadWidget}
                      setIsConfirm={setIsConfirm}
                    />
                  </div>
                );
              })}
            {handbookWidgets.length > 0 &&
              fromLandingPage &&
              teamEmployeeHandbook?.teamEmployeeHandbook?.teamHandbook
                ?.readConfirmationRequired && (
                <ConfirmHandBookReadSection
                  employeeReadConfirmation={
                    teamEmployeeHandbook.teamEmployeeHandbook.employeeReadConfirmation
                  }
                  teamId={teamId}
                />
              )}
            {handbookWidgets.length > 0 &&
              !fromLandingPage &&
              handbookWidgets.map((widget, index) => {
                const widgetType = widget.widgetType.name.toLowerCase();
                const backgroundColor = widget.background?.color ?? '#DEE6EB';
                const backgroundImage = widget.background?.image?.url ?? '';
                const textColor = widget.textColor ?? '#000000';

                return (
                  <div key={widget.id}>
                    <HandbookWidgets
                      builderRef={builderRef}
                      widget={widget}
                      index={index}
                      widgetType={widgetType}
                      backgroundColor={backgroundColor}
                      backgroundImage={backgroundImage}
                      textColor={textColor}
                      handbookWidgets={handbookWidgets}
                      setHandbookWidgets={setHandbookWidgets}
                      id={id}
                      refetch={refetch}
                      readOnly={readOnly}
                      isTemplate={isTemplate}
                      updateWidget={updateWidget}
                      useFormBuilder={useFormBuilder2}
                      fromLandingPage={fromLandingPage}
                    />
                    <EmptyComp
                      callApi={false}
                      handbookWidgets={handbookWidgets}
                      index={index}
                      teamId={teamId}
                      readWidget={readWidget}
                      setReadWidget={setReadWidget}
                      setIsConfirm={setIsConfirm}
                      renderView={(index) => {
                        setScrollIndex(index);
                      }}
                    />
                  </div>
                );
              })}

            {!readOnly && (
              <AddNewBlock
                isTemplate={isTemplate}
                handbookId={id}
                handbookWidgets={handbookWidgets}
                setHandbookWidgets={setHandbookWidgets}
                previoudWidgetId={
                  (isTemplate ? data?.template : data?.handbook)?.widgets?.find(
                    (i) => i.nextWidget === null
                  )?.id ?? null
                }
                refetch={refetch}
              />
            )}
          </div>
        )}
        {!handbookWidgets.length && (
          <h1 style={{ textAlign: 'center' }}>{i18next.t('No sections found')}</h1>
        )}
      </Col>
    </React.Fragment>
  );
};

const HandbookWidgets = ({
  widgetType,
  backgroundColor,
  backgroundImage,
  textColor,
  index,
  handbookWidgets,
  setHandbookWidgets,
  id,
  refetch,
  readOnly,
  widget,
  isTemplate,
  updateWidget,
  builderRef,
  useFormBuilder,
  fromLandingPage,
}) => {
  const { updateFormMutation } = useFormBuilder();
  const [showEditOptions, setShowEditOptions] = useState(false);
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const [isShowBlock, setShowBlock] = useState(true);
  const currentWidgetRef = useRef(null);
  const [updateForm] = updateFormMutation;
  const handleUpdateWidgetText = async (data, currentWidget) => {
    const widgetType = currentWidget.widgetType.name.toLowerCase();
    if (!data) return;
    await updateWidget({
      variables: {
        input: {
          id: currentWidget.id,
          update: { [widgetType]: data },
        },
      },
    });
  };

  const changeFormHandler = (e, widget) => {
    const updatedWidget = {
      ...widget,
      form: {
        ...widget.form,
        [e.target.name]: e.target.value,
      },
    };
    setHandbookWidgets(
      handbookWidgets.map((handbookWidget) =>
        handbookWidget.id === widget.id ? updatedWidget : handbookWidget
      )
    );
  };

  const updateFormWidgetHandler = async ({ target }, widget) => {
    const widgetType = widget.widgetType.name.toLowerCase();
    const { form } = widget;
    const { name, description } = form;
    const payload = {
      variables: {
        id: widget[widgetType].id,
        input: {
          name,
          description,
          [target.name]: target.value,
        },
      },
    };
    await updateForm(payload);
  };

  const appendNewBlock = (id, whichvalue, newvalue) => {
    let index = handbookWidgets.findIndex((x) => x.id === id);
    if (index !== -1) {
      let temporaryarray = handbookWidgets.slice();
      temporaryarray.map((v) => {
        delete v[whichvalue];
        return v;
      });
      temporaryarray[index][whichvalue] = newvalue;
      setHandbookWidgets(temporaryarray);
      setShowBlock(false);
    } else {
      console.log('no match');
    }
  };
  return (
    <div
      id={`${widgetType}-${widget.id}`}
      ref={currentWidgetRef}
      onMouseEnter={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (isColorPickerOpen || !isShowBlock) return;
        setShowEditOptions(true);
      }}
      onMouseLeave={(e) => {
        e.preventDefault();
        e.stopPropagation();

        if (isColorPickerOpen || !isShowBlock) return;
        setShowEditOptions(false);
      }}
      style={{ position: 'relative' }}
      key={index}
    >
      {widget.isNewBlockAdded && !isShowBlock && (
        <AddNewBlock
          currentIndex={index}
          isCreatingNewBlock
          handbookWidgets={handbookWidgets}
          isShowCloseButton={true}
          setHandbookWidgets={setHandbookWidgets}
          handbookId={id}
          isTemplate={isTemplate}
          refetch={refetch}
          onCloseBlock={(e) => {
            setShowBlock(true);
            widget.isNewBlockAdded = false;
          }}
        />
      )}

      {!readOnly && showEditOptions && !widget.isNewBlockAdded && (
        <React.Fragment>
          {' '}
          <BuilderEditOptions
            colorPickerCallback={(isColorPickerOpen) => {
              setIsColorPickerOpen(isColorPickerOpen);
            }}
            currentWidgetRef={currentWidgetRef}
            builderRef={builderRef}
            widget={widget}
            handbookId={id}
            handbookWidgets={handbookWidgets}
            setHandbookWidgets={setHandbookWidgets}
            curentIndex={index}
            refetchWidgets={refetch}
            backgroundColor={backgroundColor}
            textColor={textColor}
            isTemplate={isTemplate}
          />
          <div
            className="position-absolute mt-2 plus_btn_wrap"
            style={{ left: 'calc(50% - 20px)', top: 0, zIndex: 1 }}
          >
            <Button
              className="d-flex justify-content-center align-items-center "
              onClick={() => appendNewBlock(widget.id, 'isNewBlockAdded', true)}
              style={{
                border: '3px solid white',
                borderRadius: 45,
                height: 45,
                width: 45,
              }}
            >
              <img
                src={AddIcon}
                style={{
                  width: '17px',
                  height: '17px',
                  display: 'block',
                  transform: 'scale(1)',
                }}
                alt="icon"
                title={i18next.t('Add new block')}
              />
            </Button>
          </div>
        </React.Fragment>
      )}

      {(widget.chapter || widget.text) && (
        <ChapterEditor
          readOnly={readOnly}
          placeholder={widget.chapter ? i18next.t('Untitled section') : 'Lorem ipsum paragraph'}
          html={widget.chapter ?? widget.text}
          backgroundImage={backgroundImage}
          bgColor={backgroundColor}
          textColor={textColor}
          onBlur={readOnly ? null : (data) => handleUpdateWidgetText(data, widget)}
          editorId={`chapter-${index + 1}`}
          widget={widget}
        />
      )}
      {(widget.image ||
        widget.video ||
        (widgetType === 'file' && widget[widgetType]?.type?.extension === 'pdf') ||
        widget.embed) && (
          <div
            className="d-flex flex-column justify-content-center align-items-center chapter-widget"
            style={{
              borderBottom: '1px solid #C1C1C1',
              backgroundColor: backgroundColor,
              backgroundImage: `url(${backgroundImage})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center center',
              minHeight: '600px',
              textAlign: 'center',
              width: '100%',
              paddingTop: '40px',
              paddingBottom: '40px',
            }}
          >
            <WidgetInput readOnly={readOnly} widget={widget} textColor={textColor} />
            <FilePreview
              customStyles={{
                width: 'auto',
                maxWidth: '80%',
                maxHeight: '50%',
              }}
              url={widget.embed ?? widget[widgetType].url}
              type={widgetType}
              extension={widget[widgetType]?.type?.extension}
            />
            {!readOnly && (
              <UpdateFilesDialog isTemplate={isTemplate} widget={widget} refetchHandbook={refetch} />
            )}
          </div>
        )}
      {widgetType === 'talent_adore_form' && (
        <TalentAdoreFormWidget
          widget={widget}
          refetchHandbook={refetch}
          style={{
            borderBottom: '1px solid #C1C1C1',
            backgroundColor: backgroundColor,
            backgroundImage: `url(${backgroundImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            width: '100%',
            color: textColor,
          }}
          readOnly={!fromLandingPage}
        />
      )}
      {widgetType === 'form' &&
        (readOnly ? (
          <HandbookForm
            widget={widget}
            refetchHandbook={refetch}
            style={{
              borderBottom: '1px solid #C1C1C1',
              backgroundColor: backgroundColor,
              backgroundImage: `url(${backgroundImage})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center center',
              width: '100%',
              color: textColor,
            }}
          />
        ) : (
          <FormWidget
            {...useFormBuilder}
            useFormBuilder={useFormBuilder}
            widget={widget}
            refetchHandbook={refetch}
            onBlur={(e) => updateFormWidgetHandler(e, widget)}
            changeFormHandler={changeFormHandler}
            userView={readOnly}
            style={{
              borderBottom: '1px solid #C1C1C1',
              backgroundColor: backgroundColor,
              backgroundImage: `url(${backgroundImage})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center center',
              width: '100%',
              color: textColor,
            }}
          />
        ))}
      {widget[widgetType] &&
        widgetType === 'file' &&
        widget[widgetType]?.type?.extension !== 'pdf' && (
          <OtherFilesView
            readOnly={readOnly}
            backgroundImage={backgroundImage}
            backgroundColor={backgroundColor}
            item={widget}
            refetchHandbook={refetch}
          />
        )}
    </div>
  );
};

const ConfirmHandBookReadSection = ({ employeeReadConfirmation, teamId }) => {
  const { goBack } = useHistory();
  const [confirmRead, setConfirmRead] = useState(employeeReadConfirmation);
  const [updateTeamEmployeeHandbook] = useMutation(UPDATE_ONETEAM_EMPLOYEEHANDBOOK);
  const handleClickConfirm = async () => {
    if (!confirmRead) return;

    await updateTeamEmployeeHandbook({
      variables: {
        id: teamId,
        employeeReadConfirmation: confirmRead,
      },
    });

    goBack();
  };
  const onConfirmChange = () => {
    setConfirmRead(!confirmRead);
  };

  return (
    <div style={{ textAlign: 'center', height: '300px' }}>
      <h1 style={{ fontSize: '20px', marginTop: '30px' }}>
        {i18next.t('All done! Are you ready to confirm you have read the chapter?')}
      </h1>
      <p style={{ color: gray, fontSize: '15px' }}>
        {i18next.t(
          'Tick the box below to confirm that you have read and understood the content of this chapter.'
        )}
      </p>

      <div style={{ marginBottom: '15px' }}>
        <input
          type="checkbox"
          checked={confirmRead}
          onChange={onConfirmChange}
          disabled={employeeReadConfirmation}
        />{' '}
        {i18next.t('I confirm that I have read this chapter.')}
      </div>
      <Button disabled={employeeReadConfirmation} variant="secondary" onClick={handleClickConfirm}>
        {i18next.t('Confirm')}
      </Button>
    </div>
  );
};

const EmptyComp = ({
  callApi = true,
  index,
  readWidget,
  setReadWidget,
  setIsConfirm,
  teamId,
  handbookWidgets,
  renderView = () => { },
}) => {
  const ref = useRef();
  const isVisible = useOnScreen(ref);
  const [createHandbookStats] = useMutation(CREATE_HANDBOOK_STATS);

  const handleUpdateWidgetRead = async (index, handbookWidgets) => {
    if (index === handbookWidgets.length - 1) {
      setIsConfirm(true);
    } else {
      setIsConfirm(false);
    }

    if (readWidget >= index) return;
    setReadWidget(index);
    //const widget = handbookWidgets[index];

    let widgetCout = index + 1;

    await createHandbookStats({
      variables: {
        input: {
          handbookStatistic: {
            widgetsVisited: widgetCout,
            teamEmployeeHandbook: {
              id: teamId,
            },
          },
        },
      },
    });
  };

  useEffect(() => {
    async function updateDate() {
      if (isVisible && callApi) {
        await handleUpdateWidgetRead(index, handbookWidgets);
      }
      if (isVisible && !callApi) {
        renderView(index);
      }
    }
    updateDate();
  }, [isVisible]);

  return <div ref={ref} className="stats-hook" />;
};
