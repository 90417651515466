import React from 'react';
import { LeftChevronIcon } from '../../assets/icons';

export const GoBack = ({ isTemplate, fromLandingPage, history, t }) => {
    const { location, goBack, push } = history;

    const onGoBack = () => {
        if (fromLandingPage || location?.state?.shouldGoBack) {
            goBack();
            return;
        }

        if (isTemplate) {
            push('/templates')
            return;
        }

        push('/')
    }


    return (
        <div className="d-flex align-items-center sticky-top">
            <img
                onClick={onGoBack}
                src={LeftChevronIcon}
                alt="back-icon"
                className="nav-btn mx-2"
            />
            <p
                onClick={onGoBack}
                className="nav-btn mt-3"
            >
                {t('Back')}
            </p>
        </div>
    )
}